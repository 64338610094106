// src/components/Projects.js
import React from "react";
import "animate.css";

const Projects = () => {
  const projects = [
    {
      name: "Project 1",
      image:
        "https://dubbo.apache.org/imgs/docs3-v2/golang-sdk/concept/dubbogo.png",
      link: "https://github.com/your-repo1",
    },
    {
      name: "Project 2",
      image: "https://miro.medium.com/v2/resize:fit:1400/0*JCVAyOECiZbm9W7b",
      link: "https://github.com/your-repo2",
    },
    {
      name: "Project 3",
      image: "https://blog.openresty.com/header-images/openresty-logo.png",
      link: "https://github.com/your-repo3",
    },
  ];

  return (
    <section
      id="projects"
      className="text-center py-20 bg-paleYellow animate__animated animate__fadeInUp"
    >
      <h2 className="text-2xl font-bold text-mutedOrange mb-8">Projects</h2>
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {projects.map((project, index) => (
          <a
            key={index}
            href={project.link}
            className="relative group"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={project.image}
              alt={project.name}
              className="w-full h-48 object-cover rounded-lg shadow-md group-hover:shadow-lg transition-shadow duration-300"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 flex items-center justify-center transition-opacity duration-300 rounded-lg">
              <span className="text-white text-lg font-semibold">
                {project.name}
              </span>
            </div>
          </a>
        ))}
      </div>
    </section>
  );
};

export default Projects;
