// src/components/Header.js
import React from 'react';

const Header = () => {
  return (
    <header className="fixed top-0 left-0 right-0 bg-mutedOrange text-white shadow-lg z-10">
      <nav className="container mx-auto flex items-center justify-between py-4 px-6">
        <ul className="flex space-x-8">
          <li><a href="#profile" className="hover:text-softGreen">Profile</a></li>
          <li><a href="#goal" className="hover:text-softGreen">Goal</a></li>
          <li><a href="#projects" className="hover:text-softGreen">Projects</a></li>
          <li><a href="#skills" className="hover:text-softGreen">Skills</a></li>
          <li><a href="#contact" className="hover:text-softGreen">Contact</a></li>
        </ul>
        <div className="w-12 h-12">
          <img src="https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg" alt="Profile" className="w-full h-full rounded-full border-2 border-white" />
        </div>
      </nav>
    </header>
  );
}

export default Header;
