import React, { useState } from "react";
import "animate.css";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(
      "https://mybf1dcrq5.execute-api.us-west-1.amazonaws.com/message",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, subject, message }),
      }
    );

    if (response.ok) {
      alert("Message sent successfully!");
    } else {
      alert("Failed to send message.");
    }
  };

  return (
    <section
      id="contact"
      className="text-center py-20 bg-mutedOrange text-white animate__animated animate__fadeInUp"
    >
      <h2 className="text-2xl font-bold">Contact</h2>
      <form onSubmit={handleSubmit} className="flex flex-col items-center mt-4">
        <input
          type="email"
          name="email"
          placeholder="Your email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="p-2 border border-gray-300 text-black rounded mb-4 w-80"
        />
        <input
          name="subject"
          placeholder="Your subject"
          required
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="p-2 border border-gray-300 text-black rounded mb-4 w-80"
        />
        <textarea
          name="message"
          placeholder="Your message"
          required
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="p-2 border border-gray-300 text-black rounded mb-4 w-80 h-32"
        ></textarea>
        <button type="submit" className="p-2 bg-white text-mutedOrange rounded">
          Send
        </button>
      </form>
    </section>
  );
};

export default Contact;
