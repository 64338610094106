import React from "react";
import "animate.css";

const Goal = () => {
  return (
    <section
      id="goal"
      className="text-center py-20 bg-lightBeige animate__animated animate__fadeInUp"
    >
      <h2 className="text-2xl font-bold text-mutedOrange">
        Looking for a Java/Backend developer position
      </h2>
    </section>
  );
};

export default Goal;
