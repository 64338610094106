// src/components/Profile.js
import React from "react";
import "animate.css";

const Profile = () => {
  return (
    <section
      id="profile"
      className="text-center py-20 bg-softGreen animate__animated animate__fadeInUp"
    >
      <img
        src="https://www.strasys.uk/wp-content/uploads/2022/02/Depositphotos_484354208_S.jpg"
        alt="Profile"
        className="w-32 h-32 rounded-full mx-auto"
      />
      <p className="text-lg max-w-2xl mx-auto">
        Experienced Software Engineer with 3 years of experience in fintech
        development. Proven track record in modernizing legacy systems and
        developing new systems using Java and React. Adept at implementing
        automated testing, CI/CD integration, and disaster recovery solutions.
        Experienced in microservices and AWS. Seeking to leverage expertise in
        solution architecture to drive business value.
      </p>
    </section>
  );
};

export default Profile;
