// src/components/Skills.js
import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "animate.css";

const skillsData = [
  { name: "JavaScript", percentage: 85 },
  { name: "React", percentage: 80 },
  { name: "Node.js", percentage: 75 },
  { name: "AWS", percentage: 70 },
  { name: "CI/CD", percentage: 65 },
];

const SkillBar = ({ name, percentage, inView }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (inView) {
      let start = 0;
      const increment = percentage / 50; // Adjust speed here
      const interval = setInterval(() => {
        start += increment;
        setProgress(Math.min(start, percentage));
        if (start >= percentage) clearInterval(interval);
      }, 20); // Adjust interval time here
    }
  }, [inView, percentage]);

  return (
    <div className="mb-4">
      <h3 className="text-xl font-semibold text-mutedOrange mb-2">{name}</h3>
      <div className="w-full bg-gray-200 rounded-full h-6">
        <div
          className="bg-mutedOrange h-6 rounded-full transition-all duration-1000 ease-out"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className="text-right mt-1 text-mutedOrange font-bold">
        {inView && <span>{Math.round(progress)}%</span>}
      </div>
    </div>
  );
};

const Skills = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust the threshold to control when the animation starts
  });

  return (
    <section
      id="skills"
      ref={ref}
      className="text-center py-20 bg-warmBeige animate__animated animate__fadeInUp"
    >
      <h2 className="text-2xl font-bold text-mutedOrange mb-8">Skills</h2>
      <div className="container mx-auto px-4 md:px-0">
        {skillsData.map((skill, index) => (
          <SkillBar
            key={index}
            name={skill.name}
            percentage={skill.percentage}
            inView={inView}
          />
        ))}
      </div>
    </section>
  );
};

export default Skills;
